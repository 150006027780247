import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ContactFromDate from "data/sections/form-info.json";
import emailjs from '@emailjs/browser';

const ContactForm = ({fullWidthFormVisibility}) => {
  const messageRef = React.useRef(null);
  const [formVisibility,setFormVisibility] = useState(true);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateField(value){
    let error;
    if(!value)
      error = 'Required';

      return error;
  }

  const sendEmail = async (formValue,formCtrls) => {
    // e.preventDefault();
    const emailObj = {
      from_name: formValue.name,
      to_name: "Team",
      message: formValue.message,
      reply_to: formValue.email,
    }
    emailjs.send('service_elf_contact_us', 'template_elf_contact_us', emailObj, 'Wmlon1QNJOqTWh4Ax')
      .then((result) => {
        setFormVisibility(false)
        messageRef.current.innerHTML = "<span id='emoji'>👍</span><br/> Thank you for reaching out to us. We will get back to you soon.";
        // Reset the values
        formValue.name = "";
        formValue.email = "";
        formValue.message = "";
        formCtrls.resetForm();
        
      }, (error) => {
        setFormVisibility(false);  
        messageRef.current.innerHTML = "Something went wrong. Please try again!";
        
      }).finally(() => {
        // clear message
        // setTimeout(() => {
        //   console.log("hi");
        //   messageRef.current.innerHTML = '';
        //   setFormVisibility(false);
        // }, 3000);
        
      });
  };

  function goBack(){
    messageRef.current.innerHTML = '';
    setFormVisibility(true);
  }

  // const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className={fullWidthFormVisibility ? "row justify-content-center text-center" : "row"}>
          <div className={fullWidthFormVisibility ? "col-lg-8 justify-content-center" : "col-lg-6"}>
            <div className="form md-mb50">
              <h4 className="fw-700 color-font mb-50">{fullWidthFormVisibility ? "Let's Work Together" : 'Reach Us'}</h4>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                onSubmit={sendEmail}
              >
                {({ errors, touched }) => (
                 
                    <Form id="contact-form">
                    
                    {
                      formVisibility  ?
                      <>
                    <div className="controls">
                      <div className="form-group">
                        <Field
                          id="form_name"
                          validate={validateField}
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="contactus-input"
                        />
                         {errors.name && touched.name && (
                          <div className="error-msg">{errors.name}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <Field
                          validate={validateEmail}
                          id="form_email"
                          type="email"
                          name="email"
                          className="contactus-input"
                          placeholder="Email"
                        />
                        {errors.email && touched.email && (
                          <div className="error-msg">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        as="textarea"
                        validate={validateField}
                        id="form_message"
                        name="message"
                        className="contactus-input"
                        placeholder="Message"
                        rows="4"
                      />
                      {errors.message && touched.message && (
                          <div className="error-msg">{errors.message}</div>
                        )}
                    </div>
                    <button type="submit" className="nb butn bord curve">
                      <span>Send</span>
                    </button>
                    </> :
                      
                    <div className="message-cont">
                        <div className={fullWidthFormVisibility ? "text-center messages" : "text-left messages"} ref={messageRef}></div>
                        <br/>
                        <button type="button" className="nb butn bord curve" onClick={() => goBack()}><span>Go Back</span></button>
                      </div> 
                      
                    }
                    
                  </Form>
                  
                )}
              </Formik>
            </div>
          </div>
         {
          !fullWidthFormVisibility && 
          <div className="col-lg-5 offset-lg-1">
          <div className="cont-info">
            <h4 className="fw-700 color-font mb-50">Contact</h4>
            <h3 className="wow" data-splitting>
              {ContactFromDate.title}
            </h3>
            <div className="item mb-40">
              <h5>
                <a href="#0">{ContactFromDate.email}</a>
              </h5>
              <h5>{ContactFromDate.phone}</h5>
            </div>
            <h3 className="wow" data-splitting>

              <h4 className="fw-700 color-font mb-50">Experience Centers</h4>
            </h3>
            <div className="item">

                <span>
                  <b>{ContactFromDate.location.firstCity}</b>
                  <h6>{ContactFromDate.location.firstAddress}</h6>
                </span><br />
                <span>
                  <b>{ContactFromDate.location.secondCity}</b>
                  <h6>{ContactFromDate.location.secondAddress}</h6>
                </span><br />
                <span>
                  <b>{ContactFromDate.location.thirdCity}</b>
                  <h6>{ContactFromDate.location.thirdAddress}</h6>
                </span><br />
                <span>
                  <b>{ContactFromDate.location.forthCity}</b>
                  <h6>{ContactFromDate.location.forthAddress}</h6>
                </span>
                <span>
                  <b>{ContactFromDate.location.fifthCity}</b>
                  <h6>{ContactFromDate.location.fifthAddress}</h6>
                </span>



            </div>
            <div className="social mt-50">
              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href=" https://t.me/elfonze_technologies" target="_blank">
                <i className="fab fa-telegram"></i>
              </a>
              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                <i className="fab fa-quora"></i>
              </a>
              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="15px" height="18px" fill="#ffffff" className="twitter_x"><path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"/></svg>
              </a>





            </div>
          </div>
        </div>
         }
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
